import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../util/firebaseconfig";
import ViewEditCard from "../3-organisms/ViewEditCard";
import { useRecoilValue } from "recoil";
import { loggedInUserObjAtom } from "../../recoil/atom";
import { getTodaysCard } from "../../util/firebase_utils";

export default function Today() {
  const userObj = useRecoilValue(loggedInUserObjAtom);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [todaysCard, setTodaysCard] = useState({});

  const madeupcard = { id: 123, data: { title: "titlelee" } };
  // async function getTodaysCardID(db, dbUID, onFinish) {
  //   try {
  //     const today = new Date();
  //     const todaysDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  //     const q = query(collection(db, "users", dbUID, "cards"), where("studyDate", "==", todaysDate));
  //     const todaysCards = await getDocs(q);
  //     const dataPromise = todaysCards.docs.map(async (doc) => {
  //       return {
  //         id: doc.id,
  //       };
  //     });
  //     const data = await Promise.all(dataPromise);
  //     if (data.length > 0) {
  //       // setCardData(data);
  //       // setFirstCardID(data[0].id);
  //       onFinish();
  //       return data[0].id;
  //     }
  //   } catch (error) {
  //     console.log("Error while fetching data");
  //     console.log(error);
  //   }
  // }

  useEffect(() => {
    const whenDone = () => setIsLoading(false);
    console.log("2398r");
    getTodaysCard(db, userObj.dbUID).then((todaysCards) => {
      console.log("2g87");
      setIsLoading(false);
      const newCard = {};
      newCard.id = todaysCards.docs[0].id;
      newCard.data = todaysCards.docs[0].data();
      console.log("todaysCard", newCard);
      setTodaysCard(todaysCard);
    });
  }, [userObj.isLoggedIn]);

  // useEffect(() => {
  //   async function fetchData() {
  //     const today = new Date();
  //     const date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  //     const q = query(collection(db, "users", userObj.dbUID, "schedule"));
  //     try {
  //       const dbPromise = await getDocs(q);
  //       const rawData = dbPromise.docs;
  //       const data = rawData.map((doc) => ({
  //         id: doc.id,
  //         reference: doc.get("title"),
  //       }));
  //       setCardData(data);
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.log("Error while fetching data");
  //       console.log(error);
  //     }
  //   }

  //  if (userObj.isLoggedIn) {
  //     fetchData();
  //   } else {
  //     navigate("/login");
  //   }
  // }, [userObj.isLoggedIn]);

  return (
    <div>
      <p>{isLoading && "Loading ..."}</p>
      {/* <ViewEditCard cardData={todaysCard} /> */}
    </div>
  );
}
