import React, { useState, useEffect } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../util/firebaseconfig";
// import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { loggedInUserObjAtom } from "../../recoil/atom";
import { useRecoilState } from "recoil";

export default function Register() {
  const navigate = useNavigate();

  const [formInvitationCode, setFormInvitationCode] = useState("");
  const [formName, setFormName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formEmailError, setFormEmailError] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [formPasswordError, setFormPasswordError] = useState("");
  const [navTo, setNavTo] = useState("");

  const [buttonStateSpinning, setButtonStateSpinning] = useState(false);
  const [userObj, setUserObj] = useRecoilState(loggedInUserObjAtom);

  const emailIsValid = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      console.log("valid");
      return true;
    }
    console.log("invalid");
    return false;
  };

  const submitForAuthRegistration = () => {
    //// AUTH
    createUserWithEmailAndPassword(auth, formEmail, formPassword)
      .then((userCredential) => {
        console.log("AUTH: User registered with uid " + userCredential.user.uid);
        submitForDBRegistration(userCredential.user.uid);
        setNavTo("/login");
      })
      .catch((error) => {
        console.log(error);
        console.log(error.code);
        console.log(error.message);
        console.log("AUTH: Failed to register, with error: " + error.message);
      });
  };

  const submitForDBRegistration = (authUID) => {
    console.log("Registering in DB: " + formEmail + " & " + formPassword);
    setButtonStateSpinning(true);

    const newUserData = {
      authUID: authUID,
      email: formEmail,
      name: formName,
      isAdmin: false,
      isCreator: false,
      invitationCode: formInvitationCode,
    };
    const cardCollection = collection(db, "users");
    addDoc(cardCollection, newUserData)
      .then((docRef) => {
        console.log("DB: Added user with ref: " + docRef.id);
        setButtonStateSpinning(false);
        // const status = { success: true, message: "Successfully registered on Database" };
        // console.log("about to change done with reg");
        // setDoneWithReg(true);
        // console.log("changed done with reg");
      })
      .catch((error) => {
        console.log("DB: User not store on DB, errors:");
        console.log(error);
        console.log(error.code);
        console.log(error.message);
        const status = { success: false, message: "Error registering on Database", error: error };
        return status;
      });
  };

  const updateFormInvitationCode = (e) => {
    setFormInvitationCode(e.target.value);
  };
  const updateFormName = (e) => {
    setFormName(e.target.value);
  };

  const updateFormEmail = (e) => {
    setFormEmail(e.target.value);
  };
  const updateFormPassword = (e) => {
    setFormPassword(e.target.value);
  };
  const handleSubmit = () => {
    // validateForm();
    submitForAuthRegistration();
  };

  useEffect(() => {
    // for future - not actually setting userObj yet
    // if (userObj.isLoggedIn) {
    //   navigate("/cards");
    // }
    // also change to ...  [doneWithReg, userObj.isLoggedIn]);
    //////////////
    // if (doneWithReg) {
    //   navigate("/cards");
    // }
    if (navTo !== "") {
      navigate(navTo);
    }
  });

  return (
    <div>
      <div className="columns is-centered">
        <div className="column is-half">
          <section className="section "></section>

          <div className="box">
            <section className="section">
              <p>Daisy is currently in private beta.</p>
              <p>If you don't have an invitation code, please sit tight for now.</p>
            </section>
            <section className="section">
              <h1 className="title">Register</h1>
              {/* INVITE LINK */}
              <div className="field">
                {/* <label className="label">Invitation Code</label> */}
                <div className="control">
                  <input
                    className="input"
                    placeholder="Invitation Code"
                    type="text"
                    onChange={updateFormInvitationCode}
                  />
                </div>
              </div>
              {/* NAME */}
              <div className="field">
                {/* <label className="label">Name</label> */}
                <div className="control">
                  <input className="input" placeholder="Name" type="text" onChange={updateFormName} />
                </div>
              </div>
              {/* EMAIL */}
              <div className="field">
                {/* <label className="label">Email</label> */}
                <div className="control ">
                  <input
                    className={`input ${formEmailError === "" ? "" : "is-danger"} `}
                    type="email"
                    placeholder="Email"
                    onChange={updateFormEmail}
                  />
                </div>
                <p className="help is-danger">{formEmailError}</p>
              </div>
              {/* PASSWORD */}
              <div className="field">
                {/* <label className="label">Password</label> */}
                <div className="control ">
                  <input
                    className={`input ${formPasswordError === "" ? "" : "is-danger"} `}
                    placeholder="Password"
                    onChange={updateFormPassword}
                  />
                </div>
                <p className="help is-danger">{formPasswordError}</p>
              </div>
              {/* BUTTONS */}
              <br />
              <div className="field is-grouped">
                <div className="control">
                  <button className="button is-link" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
                <div className="control">
                  <button className="button is-link is-light">Cancel</button>
                </div>
              </div>
            </section>

            <br />
          </div>
          <section className="section "></section>
        </div>
      </div>
    </div>
  );
}
