import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loggedInUserObjAtom } from "../../recoil/atom";
import { useNavigate } from "react-router-dom";
import { sitecopy } from "../../util/sitecopy";

export default function Home() {
  // const navigate = useNavigate();
  const userObj = useRecoilValue(loggedInUserObjAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (userObj.isLoggedIn) {
      navigate("/today");
    }
  }, [userObj.isLoggedIn]);

  return (
    <div>
      <section className="hero is-small">
        <div className="hero-body">
          <div className="columns is-8">
            <div className="column"></div>
            <div className="column">
              <img src="/graphicassets/icons/daisy-vertical.png" alt="logo" width={224} />
              <h1 className="title">
                <br />
                {sitecopy.homepage.headline}
              </h1>
            </div>
            <div className="column"></div>
          </div>
        </div>
      </section>
      <section className="hero is-small">
        <div className="hero-body">
          <h2 className="title">{sitecopy.homepage.subline}</h2>
        </div>
      </section>
      <section className="hero is-smallm">
        <div className="hero-body"></div>
      </section>
      <section>
        <img src="/graphicassets/time-study.png" alt="girl studying at a desk" width="425" height="421" />
      </section>
      <section className="hero is-small">
        <div className="hero-body">
          <h2 className="title"> {sitecopy.homepage.thirdline}</h2>
        </div>
      </section>
      <section>
        <div className="columns is-8">
          <div className="column">
            <div className="block">
              <figure className="image homepage-mini-image">
                <img alt="lady at a water cooler" src="/graphicassets/time-watercooler.png" />
              </figure>
            </div>
          </div>
          <div className="column">
            <figure className="image homepage-mini-image">
              <img alt="man walking a dog" src="/graphicassets/time-dog.png" />
            </figure>
          </div>
          <div className="column ">
            <figure className="image homepage-mini-image ">
              <img alt="lady on a subway train" src="/graphicassets/time-train.png" />
            </figure>
          </div>
        </div>
      </section>
      <section className="hero is-small">
        <div className="hero-body"></div>
      </section>
      <section className="hero is-small">
        <div className="hero-body">
          <h2 className="title">{sitecopy.homepage.concline}</h2>
        </div>
      </section>
      <section>
        <div className="hero-body">
          <a className="button is-link is-large" href="/register">
            {sitecopy.homepage.callToActionButtonText}
          </a>
        </div>
      </section>
      <section className="hero is-small">
        <div className="hero-body"></div>
      </section>
    </div>
  );
}
