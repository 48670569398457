import React, { useEffect, useState } from "react";
import ViewEditCard from "../../3-organisms/ViewEditCard";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../util/firebaseconfig";

import { useRecoilValue } from "recoil";
import { loggedInUserObjAtom } from "../../../recoil/atom";
import { collection, getDocs, getDoc, query, where } from "firebase/firestore";
import { useRecoilState } from "recoil";
import "../../../../node_modules/react-csv-importer/dist/index.css";
// react-csv-importer/dist/index.css";

import { Importer, ImporterField } from "react-csv-importer";
import { addCard, batchAddCard_util } from "../../../util/firebase_utils";
// include the widget CSS file whichever way your bundler supports it
//import 'react-csv-importer/dist/index.css';

export default function Upload() {
  const [userObj, setUserObj] = useRecoilState(loggedInUserObjAtom);
  // PRIVATE PAGE
  // const [file, setFile] = useState();
  // const [array, setArray] = useState([]);
  const [csvData, setCsvData] = useState([]);

  // const fileReader = new FileReader();

  // const headerKeys = Object.keys(Object.assign({}, ...array));

  function loadAllAsCards(cardsData) {
    const batchData = [];

    cardsData.forEach((cardgroup) => {
      cardgroup.forEach((card) => {
        const newCardData = {
          title: card.title,
          keypoint: card.keypoint,
          body: card.content,
        };
        batchData.push(newCardData);
      });
    });
    const whenStarting = () => {
      console.log("starting");
    };
    const whenFinished = () => {
      console.log("done");
    };

    batchAddCard_util(db, userObj.dbUID, batchData, whenStarting, whenFinished);
  }

  return (
    <>
      <div className="MyImporterWrapper">
        <Importer
          dataHandler={(rows) => {
            // required, receives a list of parsed objects based on defined fields and user column mapping;
            // may be called several times if file is large
            // (if this callback returns a promise, the widget will wait for it before parsing more data)
            // setCsvData([...csvData, rows]);
            setCsvData((csvData) => [...csvData, rows]);

            // mock timeout to simulate processing
          }}
          // dataHandler={async (rows) => {
          //   // required, receives a list of parsed objects based on defined fields and user column mapping;
          //   // may be called several times if file is large
          //   // (if this callback returns a promise, the widget will wait for it before parsing more data)
          //   console.log("received batch of rows", rows);

          //   // mock timeout to simulate processing
          //   await new Promise((resolve) => setTimeout(resolve, 500));
          // }}
          chunkSize={10000} // optional, internal parsing chunk size in bytes
          defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
          restartable={false} // optional, lets user choose to upload another file when import is complete
          // onStart={({ file, fields }) => {
          //   // optional, invoked when user has mapped columns and started import
          // }}
          // onComplete={({ file, fields }) => {
          //   // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
          // }}
          onClose={() => {
            // optional, invoked when import is done and user clicked "Finish"
            // (if this is not specified, the widget lets the user upload another file)
            console.log("importer dismissed", csvData);
            loadAllAsCards(csvData);
          }}
        >
          <ImporterField name="title" label="Title" />
          <ImporterField name="keypoint" label="Keypoint" />
          <ImporterField name="content" label="Content" optional />
        </Importer>
      </div>
      <hr></hr>

      {/* 
      <table>
        <thead>
          <tr key={"header"}>
            {headerKeys.map((key) => (
              <th>{key}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {array.map((item) => (
            <tr key={item.id}>
              {Object.values(item).map((val) => (
                <td>{val}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div> */}
    </>
  );
}
