import { useNavigate, useParams } from "react-router-dom";
import Users from "./AdminUsers";
import { useRecoilState } from "recoil";
import { loggedInUserObjAtom } from "../../../recoil/atom";

export default function Admin() {
  // PRIVATE PAGE
  const [userObj, setUserObj] = useRecoilState(loggedInUserObjAtom);
  const navigate = useNavigate();
  !userObj.isLoggedIn && navigate("/login");
  !userObj.isAdmin && navigate("/login");
  //   !userObj.isAdmin && navigate("/login");
  return (
    <>
      <a href="/admin/users">Users</a>
      <br></br>
      <a href="/admin/upload">Upload</a>
      <br></br>
      <a href="/admin/cardsgrid">Cards Grid</a>
    </>
  );
}
