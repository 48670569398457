import React, { useState, useEffect } from "react";
import { doc, updateDoc, getDoc, collection, addDoc, deleteDoc, deleteField } from "firebase/firestore";
import { db } from "../../util/firebaseconfig";
import { loggedInUserObjAtom, imitatingUserObjAtom } from "../../recoil/atom";
import Furigana from "../1-atoms/Furigana";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Switch from "react-switch";
import { addCard, getCard, updateCardNew, deleteCard } from "../../util/firebase_utils";
import DateSelector from "../1-atoms/DateSelector";
import HumanDate from "../1-atoms/HumanDate";

export default function ViewEditCard({ cardData }) {
  console.log("Card data 24t2", cardData);
  const [userObj, setUserObj] = useRecoilState(loggedInUserObjAtom);
  // const isNewCard = cardData.id == "" ? true : false;
  // const isNewCard = cardData ? false : true;
  const [isNewCard, setIsNewCard] = useState(cardData ? false : true);

  // FORM DATA MANAGEMENT
  const [formTitle, setFormTitle] = useState(cardData && cardData.data.title ? cardData.data.title : "");
  const [formKeyPoint, setFormKeyPoint] = useState(cardData && cardData.data.keypoint ? cardData.data.keypoint : "");
  const [formBody, setFormBody] = useState(cardData && cardData.data.body ? cardData.data.body : "");
  const [formStudyDate, setFormStudyDate] = useState(
    cardData && cardData.data.studyDate ? cardData.data.studyDate : null
  );
  const [studyDateTodaySwitch, setStudyDateTodaySwitch] = useState(false);
  // FORM ERRORS
  const [formTitleError, setformTitleError] = useState("");
  // FORM UI MANAGEMENT
  const [buttonStateSpinning, setButtonStateSpinning] = useState(false);
  const [isLoading, setIsLoading] = useState(isNewCard ? false : false);
  const [editMode, setEditMode] = useState(isNewCard);
  const [deleteConfirmMode, setDeleteConfirmMode] = useState(false);
  const [keyPointEditMode, setKeyPointEditMode] = useState(false);
  const navigate = useNavigate();
  // const today = new Date();
  // const todaysDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  async function makeItTodaysCard() {
    console.log("in - makeItTodaysCard");
  }
  function handleSwitchChange(checked) {
    console.log("in - handleSwitchChange");
  }

  function onStart() {
    setButtonStateSpinning(true);
  }
  function onEnd() {
    setButtonStateSpinning(false);
    setEditMode(false);
  }

  async function callAddCard() {
    const newCarddata = {
      title: formTitle,
      keypoint: formKeyPoint,
      body: formBody,
      // ...(studyDateTodaySwitch && { studyDate: todaysDate }),
    };
    addCard(db, userObj.dbUID, newCarddata, onStart, onEnd);
    navigate("/cards");
  }

  async function updateCard() {
    const newCard = {
      id: cardData.id,
      ownerUID: cardData.ownerUID,
      data: {
        title: formTitle,
        keypoint: formKeyPoint,
        body: formBody,
        studyDate: formStudyDate,
      },
      // ...(studyDateTodaySwitch && { studyDate: todaysDate }),
    };
    updateCardNew(db, newCard, onStart, onEnd);
  }

  async function callDeleteCard() {
    console.log("In callDeleteCard 426y");
    function onEnd() {
      navigate("/cards");
    }
    deleteCard(db, cardData, onStart, onEnd);

    //     const docRef = doc(db, "users", userObj.dbUID, "cards", cardData.id);
    //     try {
    //       await deleteDoc(docRef);
    //       console.log("Deleted doc: " + docRef.id);
    //       setButtonStateSpinning(false);
    //       navigate("/cards");
    //     } catch (e) {
    //       console.error("Error deleting document: " + cardData.id, e);
    //       setButtonStateSpinning(false);
    //     }
    //   }
  }
  const handleSubmit = (e) => {
    if (isNewCard) {
      callAddCard();
    } else {
      updateCard();
    }
  };
  const handleMakeItTodays = (e) => {
    console.log("in - handleMakeItTodays");
  };

  const validateForm = () => {
    console.log("in - validateForm");
  };

  // TOGGLING EDIT MODE
  function goToEditMode() {
    setEditMode(true);
  }
  function goToViewMode() {
    setEditMode(false);
  }

  // FORM MANAGEMENT
  const changeTitle = (e) => {
    setFormTitle(e.target.value);
  };
  const changeKeyPoint = (e) => {
    setFormKeyPoint(e.target.value);
  };
  const changeBody = (e) => {
    setFormBody(e.target.value);
  };

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-half">
          <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "rgba(0,0,0,0)" }}>
            <p className={isLoading ? "" : "loading-hidden"}>
              <br />
              Loading ...{" "}
            </p>
          </Box>
          <br></br> <br></br>{" "}
          <div className="box">
            {/* <div className="align-right">
              <div className="switch-label">Study Today</div>
              <div className="switch-switch">
                <label>
                  <Switch
                    onChange={handleSwitchChange}
                    checked={studyDateTodaySwitch}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor={"#ecdb03"}
                    offColor={"#ccc"}
                  />
                </label>
              </div>
            </div> */}
            <div className="field">
              <br></br>

              {/* TITLE --------------------------------------------------------------------*/}

              <div className="field">
                <div className="control ">
                  <input
                    className={`input titleinput ${formTitleError == "" ? " " : "is-danger"}
                    ${editMode ? " " : "whiteborder"}
                    `}
                    onChange={changeTitle}
                    placeholder={isNewCard ? "Card title" : ""}
                    value={formTitle}
                    onFocus={() => {
                      setformTitleError("");
                      setEditMode(true);
                    }}
                  />
                </div>
              </div>
              <p className="help is-danger">{formTitleError}</p>
            </div>
            {/* KEYPOINT --------------------------------------------------------------------*/}
            <div className="field">
              {editMode && (
                <div className="control ">
                  <textarea
                    className={`textarea is-large
                    ${editMode ? " " : "whiteborder"}
                    `}
                    onChange={changeKeyPoint}
                    onFocus={() => {
                      setEditMode(true);
                    }}
                    placeholder={isNewCard ? "Key Point" : ""}
                    value={formKeyPoint}
                  />
                </div>
              )}

              {!editMode && (
                <div
                  className="textarea-overlay"
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  <Furigana text={formKeyPoint} />
                </div>
              )}
            </div>
            <div className="field">
              <div className="control ">
                <textarea
                  className={`textarea
                                 ${editMode ? " " : "whiteborder"}
                                 `}
                  placeholder={isNewCard ? "Content" : ""}
                  value={formBody}
                  onChange={changeBody}
                  onFocus={() => {
                    setEditMode(true);
                  }}
                ></textarea>
              </div>
            </div>
            <br />

            {editMode ? (
              <DateSelector
                assignedDate={formStudyDate}
                handleDateChange={(selectedDate) => {
                  setFormStudyDate(selectedDate);
                }}
              />
            ) : (
              <>{formStudyDate && <HumanDate date={formStudyDate} />}</>
            )}

            <div className="field is-grouped">
              {editMode && (
                <>
                  <div className="control">
                    {/* <button className="button is-link is-loading" onClick={handleSubmit}>
                      {buttonStateSpinning ? <i className="fa fa-spinner fa-spin" /> : "Save"}
                    </button>{" "} */}
                    <button className={`button is-link ${buttonStateSpinning && "is-loading"}`} onClick={handleSubmit}>
                      Save
                    </button>
                  </div>
                  <div className="control">
                    <button className="button is-link is-light" onClick={goToViewMode}>
                      Cancel
                    </button>
                  </div>{" "}
                </>
              )}

              {!editMode && false && (
                <div className="control">
                  <button className="button is-link is-light" onClick={goToEditMode}>
                    Edit
                  </button>
                </div>
              )}

              {deleteConfirmMode ? (
                <>
                  <a onClick={() => setDeleteConfirmMode(false)}>Cancel</a>
                  &nbsp;<a onClick={callDeleteCard}>Confirm</a>
                </>
              ) : (
                <a
                  onClick={() => {
                    setDeleteConfirmMode(true);
                  }}
                  className="has-text-grey-lighter"
                >
                  <FontAwesomeIcon icon="fas fa-trash" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

//   const today = new Date();
//   const todaysDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

//   async function makeItTodaysCard() {
//     try {
//       const docRef = doc(db, "users", userObj.dbUID, "cards", cardData.id);
//       const res = await updateDoc(docRef, { studyDate: todaysDate });
//     } catch (e) {
//       console.error("Error updating today card flag: " + cardData.id, e);
//     }
//   }

//   function handleSwitchChange(checked) {
//     setStudyDateTodaySwitch(checked);

//     async function sendTodayFlagToDB(checked) {
//       try {
//         const docRef = doc(db, "users", userObj.dbUID, "cards", cardData.id);
//         if (checked) {
//           const today = new Date();
//           const todaysDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
//           const res = await updateDoc(docRef, { studyDate: todaysDate });
//         } else {
//           const res = await updateDoc(docRef, { studyDate: deleteField() });
//         }
//       } catch (e) {
//         console.error("Error updating today card flag: " + cardData.id, e);
//       }
//     }
//     sendTodayFlagToDB(checked);
//   }

//   async function deleteCard() {
//     setButtonStateSpinning(true);
//     const docRef = doc(db, "users", userObj.dbUID, "cards", cardData.id);
//     try {
//       await deleteDoc(docRef);
//       console.log("Deleted doc: " + docRef.id);
//       setButtonStateSpinning(false);
//       navigate("/cards");
//     } catch (e) {
//       console.error("Error deleting document: " + cardData.id, e);
//       setButtonStateSpinning(false);
//     }
//   }

//   const handleSubmit = (e) => {
//     const formIsValid = validateForm();
//     if (!formIsValid) {
//       return false;
//     }
//     if (isNewCard) {
//       callAddCard();
//     } else {
//       updateCard();
//     }
//   };

//   const handleMakeItTodays = (e) => {
//     console.log("Making it todays");
//     makeItTodaysCard();
//   };

//   const validateForm = () => {
//     const errors = [];
//     if (formTitle == "") {
//       setformTitleError("Title cannot be blank");
//       return false;
//     } else {
//       return true;
//     }
//   };

//   function goToEditMode() {
//     setEditMode(true);
//   }
//   function goToViewMode() {
//     setEditMode(false);
//   }

//   useEffect(() => {
//     if (!userObj.isLoggedIn) {
//       navigate("/login");
//     } else if (!isNewCard) {
//       setFormKeyPoint(cardData.keypoint);
//     }
//   }, [userObj.isLoggedIn]);
