import React from "react";
import { loggedInUserObjAtom, cardStoreAtom } from "../../recoil/atom";
import { useRecoilState } from "recoil";

export default function DebugUserObj() {
  const [userObj, setUserObj] = useRecoilState(loggedInUserObjAtom);
  return (
    <>
      <h2>User Obj</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>authUID</td>
            <td>{userObj.authUID}</td>
          </tr>
          <tr>
            <td>authEmail</td>
            <td>{userObj.authEmail}</td>
          </tr>
          <tr>
            <td>dbUID</td>
            <td>{userObj.dbUID}</td>
          </tr>
          <tr>
            <td>isLoggedIn</td>
            <td>{userObj.isLoggedIn ? "yes" : "no"}</td>
          </tr>
          <tr>
            <td>email</td>
            <td>{userObj.email}</td>
          </tr>
          <tr>
            <td>invitationCode</td>
            <td>{userObj.invitationCode}</td>
          </tr>
          <tr>
            <td>isAdmin</td>
            <td>{userObj.isAdmin ? "yes" : "no"}</td>
          </tr>
          <tr>
            <td>isCreator</td>
            <td>{userObj.isCreator ? "yes" : "no"}</td>
          </tr>
          <tr>
            <td>name</td>
            <td>{userObj.name}</td>
          </tr>
          <tr>
            <td>hasManagees</td>
            <td>{userObj.hasManagees ? "yes" : "no"}</td>
          </tr>
        </tbody>
      </table>
      <h2>Managees</h2>
      <table className="table">
        <tbody>
          {userObj.managees.map((managee) => {
            return (
              <React.Fragment key={managee.dbUID}>
                <tr>
                  <td>{managee.dbUID}</td>
                  <td>{managee.name}</td>
                  <td>{managee.email}</td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
