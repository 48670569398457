import React from "react";
import { isValidDate } from "../../util/Util";
import { getTodayMidnight, areDatesSame } from "../..//util/Util";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default function HumanDate({ date }) {
  if (!date || !isValidDate) {
    return <></>;
  }

  const today = getTodayMidnight();

  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  if (areDatesSame(date, today)) {
    return <div>Today</div>;
  }

  if (areDatesSame(date, tomorrow)) {
    return <div>Tomorrow</div>;
  }

  const displayDayOfWeek = days[date.getDay()];
  const displayDate = date.getDate();
  const displayMonth = months[date.getMonth()];
  const displayYear = date.getFullYear();
  const isDateThisYear = date.getFullYear() == today.getFullYear();

  let humanDate = "";
  if (isDateThisYear) {
    humanDate = displayDayOfWeek + " " + displayMonth + " " + displayDate;
  } else {
    humanDate = displayDayOfWeek + " " + displayMonth + " " + displayDate + " " + displayYear;
  }
  return <div>{humanDate}</div>;
}
