import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      {/* <footer className="footer ">
        <div className="content has-text-centered"></div>
      </footer> */}
      <p>© Daisy 2023</p>
    </div>
  );
}
