import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { auth } from "../../util/firebaseconfig";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { loggedInUserObjAtom, viewingAsObjAtom, cardsViewTypeAtom } from "../../recoil/atom";

export default function SignOutButton() {
  const navigate = useNavigate();
  const [buttonStateSpinning, setButtonStateSpinning] = useState(false);
  const [userObj, setUserObj] = useRecoilState(loggedInUserObjAtom);
  const [viewingAsUserObj, setViewingAsUserObj] = useRecoilState(viewingAsObjAtom);

  const handleClick = () => {
    console.log("Signing out");
    setButtonStateSpinning(true);
    setTimeout(() => {
      signOut(auth)
        .then(() => {
          // setButtonStateSpinning(false);
          console.log("Signed out");
          setUserObj({ isLoggedIn: false });
          setViewingAsUserObj({});
          // navigate("/login");
        })
        .catch((error) => {
          // setButtonStateSpinning(false);
          console.log("An error occurred trying to sign out");
        });
    }, 700);
  };

  useEffect(() => {}, [userObj.isLoggedIn]);

  if (userObj.isLoggedIn) {
    return (
      <div>
        <a className="button is-link" onClick={handleClick}>
          {buttonStateSpinning ? <i className="fa fa-spinner fa-spin" /> : "Sign out"}
        </a>
      </div>
    );
  } else {
    return <></>;
  }
}
