import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
// RECOIL
import { useRecoilValue } from "recoil";
import { loggedInUserObjAtom } from "./recoil/atom";
// PAGES
import Footer from "./components/3-organisms/Footer";
import FooterDebug from "./components/3-organisms/FooterDebug";
import Nav from "./components/3-organisms/Nav";
import Admin from "./components/5-pages/admin/Admin";
import AdminUsers from "./components/5-pages/admin/AdminUsers";
import Upload from "./components/5-pages/admin/Upload";
import AllComponents from "./util/AllComponents";
import Card from "./components/5-pages/Card";
import Cards from "./components/5-pages/Cards";
import CardsGrid from "./components/5-pages/CardsGrid";
import Home from "./components/5-pages/Home";
import Login from "./components/5-pages/Login";
import People from "./components/5-pages/People";
import Register from "./components/5-pages/Register";
import TestPage from "./components/5-pages/TestPage";
import Today from "./components/5-pages/Today";

// FONT AWESOME
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faGlasses, faLock, faTrash, faUnlock } from "@fortawesome/free-solid-svg-icons";
library.add(faEdit, faLock, faUnlock, faTrash, faGlasses);

function App() {
  const userObj = useRecoilValue(loggedInUserObjAtom);
  return (
    <div className="App">
      <div className="container">
        {/* <UserProvider value={user}> */}
        <Nav />{" "}
        <section className="hero is-small">
          <div className="hero-body"></div>
        </section>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/card" element={<Card />} />
          <Route path="/card/:cardID" element={<Card />} />
          <Route path="/cards" element={<Cards />} />
          <Route path="/cardsgrid" element={<CardsGrid />} />
          <Route path="/today" element={<Today />} />
          <Route path="/people" element={<People />} />
          <Route path="/allcomponents" element={<AllComponents />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/cardsgrid" element={<CardsGrid />} />
          <Route path="/admin/upload" element={<Upload />} />
          <Route path="/admin/users" element={<AdminUsers />} />
          <Route path="/test" element={<TestPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
        {userObj.isLoggedIn && userObj.isAdmin && <FooterDebug />}
        {/* </UserProvider> */}
        <section className="hero is-medium">
          <div className="hero-body"></div>
        </section>
        <Footer />
        {process.env.NODE_ENV == "development" && <FooterDebug />}
      </div>
    </div>
  );
}

export default App;
