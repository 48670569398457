import React, { useEffect, useState } from "react";

export default function Furigana({ text }) {
  const [splitOutArr, setSplitOutArr] = useState([]);

  function processText() {
    const newtext = text.replace(/（/g, "(").replace(/）/g, ")");
    let splitout = [];
    let isFurigana = false;
    let furigana = "";
    for (let index = 0; index <= newtext.length; index++) {
      const char = newtext[index];
      // FIRST PROCESS FURIGANA STARTS AND STOPS
      if (char === "(") {
        isFurigana = true;
        continue;
      }
      if (char === ")") {
        isFurigana = false;
        splitout[splitout.length - 1][1] = furigana;
        furigana = "";
        continue;
      }
      // NOW PROCESS LETTERS
      if (isFurigana) {
        if (char === "(") {
          isFurigana = false;
          splitout[splitout.length - 1][1] = furigana;
          // splitout[splitout.length-1].push(furigana) // instead of adding empty second element to every array pair
        } else {
          furigana = furigana + char;
        }
        continue;
      }
      // IS NOT FURIGANA
      splitout.push([char, ""]);
    }

    setSplitOutArr(splitout);
  }

  const parasArr = [];
  let para = [];
  for (let index = 0; index < splitOutArr.length; index++) {
    const pair = splitOutArr[index];
    if (pair[0] === "\n" || index === splitOutArr.length - 1) {
      parasArr.push(para);
      para = [];
    } else {
      para.push(pair);
    }
  }
  useEffect(() => {
    processText();
  }, [text]);

  return (
    <div>
      <span className="furikan-block">
        {parasArr.map((para, index) => {
          return (
            <React.Fragment key={index}>
              <p className="furigana-para">
                {para.map((wordpair, index) => {
                  return (
                    <React.Fragment key={index}>
                      <span className="furikan-word">
                        <span className="furigana">{wordpair[1]}</span>
                        <span className="kanji">{wordpair[0]}</span>
                      </span>{" "}
                    </React.Fragment>
                  );
                })}
              </p>
            </React.Fragment>
          );
        })}
      </span>
    </div>
  );

  // return (
  //   <div>
  //     <span className="furikan-block">
  //       <p>
  //         {splitOutArr.map((pair, index) => {
  //           return (
  //             <React.Fragment key={index}>
  //               <span className="furikan-word  ">
  //                 <span className="furigana">{pair[1]}</span>
  //                 <span className="kanji">{pair[0]}</span>
  //               </span>
  //               {pair[0] === "\n" && <p></p>}
  //             </React.Fragment>
  //           );
  //         })}
  //       </p>
  //     </span>
  //   </div>
  // );
}

{
  /* <div contentEditable="true" onFocus={focusMe} onBlur={blurMe}> */
}
