import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loggedInUserObjAtom } from "../../../recoil/atom";
import { collection, getDocs, query, deleteDoc, doc, where } from "firebase/firestore";
import { getAuth, deleteUser, getUser } from "firebase/auth";
import Login from "../Login";
import { db, auth } from "../../../util/firebaseconfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AdminUsers() {
  // PRIVATE PAGE
  const [userObj, setUserObj] = useRecoilState(loggedInUserObjAtom);
  const navigate = useNavigate();
  !userObj.isLoggedIn && navigate("/login");
  !userObj.isAdmin && navigate("/login");
  //   !userObj.isAdmin && navigate("/login");

  const [usersDBData, setUsersDBData] = useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);
  const [deleteCandidate, setDeleteCandidate] = React.useState("");
  const [triggerRender, setTriggerRender] = React.useState(false);

  useEffect(() => {
    async function fetchDBData() {
      const q = query(collection(db, "users"));
      const dbPromise = await getDocs(q);
      const rawData = dbPromise.docs;
      // rawData.forEach((doc) => console.log(doc.data()));

      const data = rawData.map((doc) => ({
        dbUID: doc.id,
        email: doc.get("email") ? doc.get("email") : "",
        name: doc.get("name") ? doc.get("name") : "",
        isAdmin: doc.get("isAdmin") ? doc.get("isAdmin") : false,
        isCreator: doc.get("isCreator") ? doc.get("isCreator") : false,
      }));
      setUsersDBData(data);
    }
    fetchDBData();
  }, [triggerRender]);

  const deleteUser = (dbUID) => {
    console.log("Deleting user " + deleteCandidate);

    // DATABASE
    deleteDoc(doc(db, "users", deleteCandidate)).then(() => {
      console.log("Confirmed deletion of DB record " + dbUID);
      setDeleteConfirm(false);
      setDeleteCandidate(undefined);
      setTriggerRender(!triggerRender);
    });

    // AUTH
    // const auth = getAuth();
    // const user = auth.currentUser;
    // Need to create a "user" object to delete it

    // const thisUser = getUser(dbUID);
    // console.log("auth user is ");
    // console.log(userr);
    // deleteUser(user)
    //   .then(() => {
    //     // User deleted.
    //   })
    //   .catch((error) => {
    //     // An error ocurred
    //     // ...
    //   });
  };

  const handleClick = () => {};

  return (
    <>
      <table className="table is-left">
        <thead>
          <tr>
            <th>uid</th>
            <th>Name</th>
            <th>Email</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {usersDBData.map((user) => (
            // Without the `key`, React will fire a key warning
            <React.Fragment key={user.dbUID}>
              <tr>
                <td className="is-left">{user.dbUID}</td>
                <td className="is-left">{user.name}</td>
                <td className="is-left">{user.email}</td>
                <td className="is-left">
                  {user.isAdmin && "Admin"}
                  {user.isAdmin && user.isCreator && ", "}
                  {user.isCreator && "Creator"}
                </td>

                <td className="is-left">
                  <a onClick={handleClick}>Reset Password</a>{" "}
                  <a onClick={handleClick} className="has-text-grey-lighter">
                    <FontAwesomeIcon icon="fas fa-edit" />
                  </a>{" "}
                  {/* <a onClick={handleClick} className="has-text-grey-lighter">
                    <FontAwesomeIcon icon="fas fa-lock" />
                  </a>{" "}
                  <a onClick={handleClick} className="has-text-grey-lighter">
                    <FontAwesomeIcon icon="fas fa-unlock" />
                  </a>{" "} */}
                  {deleteConfirm && deleteCandidate == user.dbUID ? (
                    <>
                      <a onClick={() => setDeleteConfirm(false)}>Cancel</a>{" "}
                      <a onClick={() => deleteUser(user.dbUID)}>Confirm</a>
                    </>
                  ) : (
                    <a
                      onClick={() => {
                        setDeleteConfirm(true);
                        setDeleteCandidate(user.dbUID);
                      }}
                      className="has-text-grey-lighter"
                    >
                      <FontAwesomeIcon icon="fas fa-trash" />
                    </a>
                  )}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>{" "}
      {/* <div className="is-left">{JSON.stringify(usersDBData)}</div>
      <div className="is-left">{JSON.stringify(deleteCandidate)}</div>
      <div className="is-left">{deleteConfirm ? "deleteconfirm-true" : "deleteconfirm-false"}</div> */}
    </>
  );
}
