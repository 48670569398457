export const sitecopy = {
  nav: { signInButtonText: "Sign in", signOutButtonText: "Sign Out", signUpButtonText: "Sign Up" },
  homepage: {
    headline: "Tiny Daily Lessons",
    subline: "If you don't have time to a study a bunch of phrases 10 times at your desk ...",
    thirdline: "Get one into your head and study it 200 times when you're ...",
    concline: "Daisy works using tiny, memorable lessons.",
    callToActionButtonText: "Get Started",
  },
};
