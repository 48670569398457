import React from "react";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import SignOutButton from "../1-atoms/SignOutButton";
import { loggedInUserObjAtom } from "../../recoil/atom";
// import DebugUserObj from "./DebugUserObj";
// import { firebaseUser } from "../../util/firebase";
export default function FooterDebug() {
  const userObj = useRecoilValue(loggedInUserObjAtom);

  return (
    <div>
      <hr />
      <footer className="debugfooter">
        <div className="content has-text-centered">
          <h3>Debug Footer</h3>
        </div>
        <a href="/register">Register</a>
        <Link to="/">Home</Link>
        <br />
        <Link to="/cards">Cards</Link>
        <br />
        <Link to="/cardsgrid">Cards Grid</Link>
        <br />
        <Link to="/newcard">New card</Link>
        <br />
        <Link to="/register">Register</Link>
        <br /> <Link to="/allcomponents">AllComponents</Link>
        <br />
        <br />
        <p></p>
        {/* <DebugUserObj /> <br /> */}
        <button
          onClick={() => {
            console.log(
              "useobj",
              // userObj.managees.map((managee) => {})
              userObj
            );
          }}
        >
          Console Log the User object
        </button>
        <br /> &nbsp;
        <br /> &nbsp;
        <br /> &nbsp;
      </footer>
    </div>
  );
}
