import React from "react";

export default function SignInButton() {
  return (
    <>
      <a className="button is-light" href="/login">
        Log in
      </a>
    </>
  );
}
