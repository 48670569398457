import React, { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { loggedInUserObjAtom, manageesObjAtom, viewingAsObjAtom } from "../../recoil/atom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ManageesDropdown() {
  // PROPS
  // const setSelectedManagee = props.setSelectedManageeFunc;
  // RECOIL
  const userObj = useRecoilValue(loggedInUserObjAtom);
  const managees = userObj.managees;
  const [viewingAsUserObj, setViewingAsUserObj] = useRecoilState(viewingAsObjAtom);
  // LOCAL STATE
  // const [localViewingUserName, setLocalViewingUserName] = useState("");
  const [dropdownActive, setDropdownActive] = useState(false);

  function clickHandler(managee) {
    // LOCAL
    setDropdownActive(false);
    // setLocalViewingUserName(managee.name ? managee.name : "");
    // PARENT
    handleSelection(managee);
  }

  function handleSelection(selectedUserObj) {
    setViewingAsUserObj(selectedUserObj);
  }

  useEffect(() => {
    handleSelection(viewingAsUserObj);
  }, [viewingAsUserObj]);

  return (
    <>
      <div
        className={`dropdown ${dropdownActive ? " is-active" : ""}`}
        onClick={() => {
          setDropdownActive(!dropdownActive);
        }}
      >
        <div className="dropdown-trigger">
          <button className="button" aria-haspopup="true" aria-controls="dropdown-menu">
            {/* <span>{localViewingUserName == "" ? "Select User" : localViewingUserName}</span> */}
            <span>{!viewingAsUserObj.name ? <FontAwesomeIcon icon="fas fa-glasses" /> : viewingAsUserObj.name}</span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <React.Fragment key={"0000"}>
              <a
                onClick={() => {
                  clickHandler({});
                }}
                className="dropdown-item"
              >
                Clear
              </a>
            </React.Fragment>

            {managees.map((managee) => {
              return (
                <React.Fragment key={managee.dbUID}>
                  <a
                    onClick={() => {
                      clickHandler(managee);
                    }}
                    className="dropdown-item"
                  >
                    {managee.name}
                  </a>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
