import React from "react";

export default function Tabs({ tabs, selected, onSelect }) {
  return (
    <div className="tabs  ">
      <ul>
        {tabs.map((tab) => {
          return (
            <React.Fragment key={tab.id}>
              <li className={`  ${(tab.id == selected) == "" ? " " : "is-active"}`}>
                <a
                  onClick={() => {
                    onSelect(tab.id);
                  }}
                >
                  {tab.label}
                </a>
              </li>
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
}
