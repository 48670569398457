import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loggedInUserObjAtom } from "../../recoil/atom";
import { useNavigate } from "react-router-dom";
import { useManagees } from "../../util/useManagees";
import { db } from "../../util/firebaseconfig";

export default function TestPage() {
  const userObj = useRecoilValue(loggedInUserObjAtom);

  const { managees, isLoading, error } = useManagees(db, userObj);
  console.log("-managees", managees);
  console.log("-isLoading", isLoading);
  console.log("-error", error);
  if (error) {
    return <p>An error occurred: {error.message}</p>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <h1>People</h1>
      <h2>Invite someone to manage your cards</h2>
      {/* {manageesObj.map((managee) => {
        return (
          <React.Fragment key={managee.id}>
            <h4>{managee.name}</h4>
            <h5>{managee.email}</h5>
          </React.Fragment>
        );
      })} */}
    </>
  );
}

// <script>
// console.log('---------------------')
// const rj=true
// function takeTime(timeout) {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       console.log(`This call took ${timeout} seconds`);
//       rj ? resolve("rrere") : reject("gfgfg");
//     }, timeout * 1000);
//   });
// }
// async function doasync(){
//   console.log('start')
//   try{
//     const response = await takeTime(1)
//     console.log('resolved->',response)
//     console.log('aaa')
//     return response
//   }
//   catch(e){
//     console.log('rejected->',e)
//   }
//   console.log('stop')
// }
// function dothen(){
//   console.log('start')
//   takeTime(1)
//   .then(
//     (response)=>{
//       console.log("resolved-->",response)
//       console.log('y')
//       return response
//     }
//   )
//   .catch(
//     (response)=>{
//       console.log("rejected->",response)
//       console.log('yy')
//     }
//   )
//   console.log('stop')
// }

// console.log("x")
// const allpr=[]
// const pr=doasync()
// const pr2=doasync()
// allpr.push(pr)
// allpr.push(pr2)
// //const pr=dothen()
// console.log(pr)
// //setTimeout(()=>{console.log(pr)},2000)
// //setTimeout(()=>{console.log(allpr)},2000)
// console.log(allpr)

// console.log("xx")

//   console.log("allpr",allpr);

// Promise.all(allpr).then((values) => {
//   console.log("values",values);
// });

// async function isitasync(){
// const a=4
//   return a
// }

// const aa = isitasync()
// console.log("aa-->",aa, typeof(aa))
// Promise.all([aa]).then((values) => {
//   console.log("valuesxxx",values[0]);
// });

// </script>

// <input type=button onClick="show_random_number()" value="Generate random number"></input>
