import { async } from "@firebase/util";
import {
  doc,
  updateDoc,
  getDoc,
  collection,
  addDoc,
  deleteDoc,
  deleteField,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { getTodayMidnight } from "./Util";

export async function addCard(db, uid, data, onStart, onEnd) {
  onStart();
  let result = {};
  try {
    const cardCollection = collection(db, "users", uid, "cards");
    const docRef = await addDoc(cardCollection, data);
    result = { code: 0, message: "Added doc successfully", docID: docRef.id };
  } catch (e) {
    result = { code: -1, message: "Error adding document", error: e };
  }
  onEnd();
  return result;
}

//     const docRef = doc(db, "users", userObj.dbUID, "cards", cardData.id);
//     try {
//       await deleteDoc(docRef);
//       console.log("Deleted doc: " + docRef.id);
//       setButtonStateSpinning(false);
//       navigate("/cards");
//     } catch (e) {
//       console.error("Error deleting document: " + cardData.id, e);
//       setButtonStateSpinning(false);
//     }
//   }

export async function deleteCard(db, card, onStart, onEnd) {
  // console.log("In delete card 2r3");
  // console.log(db, card, onStart, onEnd);
  onStart();
  try {
    const docRef = doc(db, "users", card.ownerUID, "cards", card.id);
    await deleteDoc(docRef, card.data);
    onEnd();
  } catch (e) {
    console.error("Error deleting document: " + card.id, e);
    onEnd();
  }
}

export async function updateCardNew(db, card, onStart, onEnd) {
  // const newtext = text.replace(/（/g, "(").replace(/）/g, ")");
  onStart();
  try {
    const docRef = doc(db, "users", card.ownerUID, "cards", card.id);
    await updateDoc(docRef, card.data);
  } catch (e) {
    console.error("Error updating document: " + card.id, e);
  }
  onEnd();
}

export async function batchAddCard_util(db, uid, batchData, onStart, onEnd) {
  const whenStarting = () => {};
  const whenFinished = () => {};
  const data = batchData[0];

  batchData.forEach((cardData) => {
    addCard(db, uid, cardData, whenStarting, whenFinished);
  });
}

export async function getManagees(db, dbUID) {
  const q = query(collection(db, "users", dbUID, "access"), where("type", "==", "student"));
  const manageesPromise = await getDocs(q);
  const managees = manageesPromise.docs.map((doc) => {
    const newobj = doc.data();
    const docRef = doc(db, "users", newobj.userRef.id);
    getDoc(docRef).then((result) => {
      newobj.refsData = result.data();
    });
    return newobj;
  });
}

export async function getCard(db, userID, cardID) {
  const docRef = doc(db, "users", userID, "cards", cardID);
  try {
    const docSnap = await getDoc(docRef); /// TO DO: Mirror the getcards structure
    const cardData = docSnap.data();
    cardData.id = docSnap.id;
    cardData.ownerUID = docSnap.ref.parent.parent.id;
    // console.log("In firebase getCard, retrieved card tw33", cardData);
    if (cardData.studyDate) {
      try {
        cardData.studyDate = cardData.studyDate.toDate();
      } catch (e) {
        console.log(
          "Error collecting card ",
          cardData.data.title,
          "toDate error on date",
          cardData.data.studyDate,
          "( type:",
          typeof cardData.studyDate,
          ")",
          e
        );
        cardData.studyDate = null;
      }
    }

    return cardData;
  } catch (error) {
    console.log(error);
  }
}

export async function getCards(db, dbUID, onEnd) {
  const q = query(collection(db, "users", dbUID, "cards"), orderBy("title", "desc"));
  try {
    const dbPromise = await getDocs(q);
    const data = dbPromise.docs.map((doc) => {
      const card = {
        // ...doc.data(), // TO DO: get rid of this and just use the data at lower level
        data: doc.data(),
        id: doc.id,
        ownerUID: doc.ref.parent.parent.id,
      };
      if (card.data.studyDate) {
        try {
          card.data.studyDate = card.data.studyDate.toDate();
        } catch (e) {
          console.log(
            "Error collecting card ",
            card.data.title,
            "toDate error on date",
            card.data.studyDate,
            "( type:",
            typeof card.data.studyDate,
            ")",
            e
          );
          card.data.studyDate = null;
        }
      }
      return card;
    });
    onEnd();
    // console.log("in get cards got data: g2g2", data);
    return data;
  } catch (error) {
    console.log("Error while fetching data");
    console.log(error);
    onEnd();
  }
}

export async function getTodaysCard(db, dbUID) {
  try {
    // const today = new Date();
    const today = getTodayMidnight();

    // const todaysDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

    const q = query(collection(db, "users", dbUID, "cards"), where("studyDate", "==", today));
    const todaysCards = getDocs(q);
    return todaysCards;

    // const dataPromise = todaysCards.docs.map(async (doc) => {
    //   console.log("doc.id y78", doc.id);
    //   return doc;
    // });
    // const data = await Promise.all(dataPromise);
    if (todaysCards.length > 0) {
      // setCardData(data);
      // setFirstCardID(data[0].id);
      return todaysCards[0];
    }
  } catch (error) {
    console.log("Error while fetching data");
    console.log(error);
  }
}
