import React, { useEffect, useState } from "react";
import { db } from "../../util/firebaseconfig";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { loggedInUserObjAtom, viewingAsObjAtom, cardsViewTypeAtom } from "../../recoil/atom";
import { getCards } from "../../util/firebase_utils";
import HumanDate from "../1-atoms/HumanDate";
import Tabs from "../1-atoms/Tabs";
// import { cardStoreAtom } from "../../recoil/atom";
import PropTypes from "prop-types";
import NewCardButton from "../1-atoms/NewCardButton";
import { getTodayMidnight, areDatesSame, daysDiff } from "../..//util/Util";

function Cards() {
  const navigate = useNavigate();
  const userObj = useRecoilValue(loggedInUserObjAtom);
  const [cardData, setCardData] = useState([]);
  const [viewingCardData, setViewingCardData] = useState(cardData);
  const [isLoading, setIsLoading] = useState(true);
  const [viewingAsUserObj, setViewingAsUserObj] = useRecoilState(viewingAsObjAtom);
  const [cardsViewType, setCardsViewType] = useRecoilState(cardsViewTypeAtom);

  useEffect(() => {
    if (!userObj.isLoggedIn) {
      navigate("/login");
    }
    const onCardsLoaded = () => {
      setIsLoading(false);
    };
    // const viewUser = viewingAsUserObj.dbUID ? viewingAsUserObj.dbUID : userObj.dbUID;
    const viewUser = viewingAsUserObj.dbUID || userObj.dbUID;
    getCards(db, viewUser, onCardsLoaded).then((data) => {
      setCardData(data);
    });
    //  setCardStore(data);
  }, [userObj.isLoggedIn, viewingAsUserObj]);

  useEffect(() => {
    const today = getTodayMidnight();
    let newCardsArray = cardData.slice();
    switch (cardsViewType) {
      case "all":
        newCardsArray = newCardsArray.sort((a, b) => a.data.title - b.data.title);
        break;
      case "recent":
        newCardsArray = newCardsArray
          .filter((card) => card.data.studyDate != undefined)
          // .filter((card) => card.data.studyDate <= today)
          .filter((card) => daysDiff(card.data.studyDate, today) > -1)
          .filter((card) => daysDiff(card.data.studyDate, today) < 7)
          .sort((a, b) => b.data.studyDate - a.data.studyDate);
        break;
      case "upcoming":
        newCardsArray = newCardsArray
          .filter((card) => card.data.studyDate >= today)
          .sort((a, b) => a.data.studyDate - b.data.studyDate);
        break;
      case "unqueuedstore":
        newCardsArray = newCardsArray.filter((card) => card.data.studyDate == null);
        break;
      // case "today":
      //   newCardsArray = newCardsArray.filter((card) => {
      //     // if (!card || !card.data || !card.data.studyDate) {
      //     if (card.data.studyDate == undefined) {
      //       return false;
      //     }
      //     return areDatesSame(card.data.studyDate, today);
      //     // return card.data.studyDate > today - 7;
      //   });
      //   break;
      default:
        break;
    }
    setViewingCardData(newCardsArray);
  }, [cardsViewType, cardData]);

  return (
    <div className="columns is-centered">
      <div className="column is-half">
        {/* <DebugUserObj object={userObj} /> */}
        <Box sx={{ width: "100%", maxWidth: 100, bgcolor: "background.paper" }}>
          <Link to="/card" className="navbar-item">
            New Card
          </Link>{" "}
          <Divider />
        </Box>
        <Box sx={{ width: "100%", maxWidth: 500, bgcolor: "rgba(0,0,0,0)" }}>
          <p className={isLoading ? "" : "loading-hidden"}>
            <br />
            Loading ...{" "}
          </p>
        </Box>
        {/* <Box sx={{ width: "100%", maxWidth: 500, bgcolor: "rgba(0,0,0,0)" }}>
          <a onClick={() => setCardsViewType("all")}>All A-Z</a>
          <a onClick={() => setCardsViewType("history")}>History</a>{" "}
          <a onClick={() => setCardsViewType("upcoming")}>Upcoming</a>{" "}
          <a onClick={() => setCardsViewType("unqueuedstore")}>Store</a>
          <Divider />
        </Box> */}
        <Tabs
          tabs={[
            { label: "All A-Z", id: "all" },
            // { label: "Today", id: "today" },
            { label: "Recent", id: "recent" },
            { label: "Upcoming", id: "upcoming" },
            { label: "Unscheduled", id: "unqueuedstore" },
          ]}
          selected={cardsViewType}
          onSelect={(id) => setCardsViewType(id)}
        />
        <Box sx={{ width: "100%", maxWidth: 500, bgcolor: "background.paper" }}>
          <nav aria-label="secondary mailbox folders">
            <List>
              {/* className={`input ${loadingShowing ? "" : "loading-hidden"} `} */}

              {viewingCardData.map((card) => (
                <React.Fragment key={card.id}>
                  <HumanDate date={card.data.studyDate} />
                  <ListItem
                    component={Link}
                    to={`/card/`}
                    divider={true}
                    key="2212e1"
                    state={{
                      cardddData: card,
                    }}
                  >
                    <ListItemText primary={card.data.title} secondary={card.data.keypoint} />{" "}
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </nav>
        </Box>
      </div>
    </div>
  );
}

Cards.propTypes = {};

export default Cards;
