import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";

// https://stackoverflow.com/questions/38722963/how-to-create-a-javascript-library-that-can-be-used-in-multiple-projects/38723237#38723237

// (function(api){
//   api.myFn=function(a,b){ /*"myFn" is attached to nameSpace*/
//        return a+b;
//   };
//   window.myLibrary=api; /*Publish your library to be used outside of anonymous function */
// })({}) /*{} is the value of api*/

// console.log(
// myLibrary.myFn(4,5)
// )
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASURMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore(app);
// const analytics = getAnalytics(app);

onAuthStateChanged(auth, (user) => {
  // const [liuser, setLoggedInUser] = useRecoilState(loggedInUser);
  if (user) {
    // setLoggedInUser(user);
    // setLoggedInUser("joe.schmoe");
    // // firebaseUser.uid = user.uid;
    // signedInUser.uid = user.uid;
    // signedInUser.loggedIn = true;
    // signedInUser.displayName = user.displayName;
    // signedInUser.email = user.email;
  } else {
    // setLoggedInUser(user);
    // signedInUser.uid = undefined;
    // signedInUser.loggedIn = false;
  }
  // console.log(SignedInUser);
});

export {
  // registerUser,
  // firestoreAddCard,
  // firestoreGetCards,
  // firebaseSignIn,
  // signInWithEmailAndPassword,
  // getDocs,
  auth,
  db,
  // collection,
};
