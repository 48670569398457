import React, { useState } from "react";
import { Link } from "react-router-dom";
import SignOutButton from "../1-atoms/SignOutButton";
import SignUpButton from "../1-atoms/SignUpButton";
import SignInButton from "../1-atoms/SignInButton";
// import { useNavigate } from "react-router-dom";

import { useRecoilState, useRecoilValue } from "recoil";
import { loggedInUserObjAtom, viewingAsObjAtom } from "../../recoil/atom";
import ManageesDropdown from "../1-atoms/ManageesDropdown";

export default function Nav() {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const [userObj, setUserObj] = useRecoilState(loggedInUserObjAtom);
  const [viewingAsUserObj, setViewingAsUserObj] = useRecoilState(viewingAsObjAtom);

  const burgerClick = () => {
    setBurgerOpen(!burgerOpen);
  };

  return (
    <div>
      <nav className="navbar " role="navigation" aria-label="main navigation">
        {/* BRAND - ALWAYS VISIBLE */}
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src="../../../graphicassets/icons/daisy.png" width={112} height={28} />
          </a>

          <a
            role="button"
            className={`navbar-burger ${burgerOpen ? "is-active" : ""} `}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={burgerClick}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        {/* MENU - DESKTOP */}
        <div id="navbarBasicExample" className={`navbar-menu ${burgerOpen ? "is-active" : ""} `}>
          {" "}
          {/* LEFT SIDE */}
          <div className="navbar-start">
            {userObj.isLoggedIn && (
              <Link to="/today" className="navbar-item" onClick={burgerClick}>
                Today
              </Link>
            )}

            {userObj.isLoggedIn && (
              <Link to="/cards" className="navbar-item" onClick={burgerClick}>
                Cards
              </Link>
            )}
            {/* 
            {userObj.isLoggedIn && userObj.hasManagees && (
              <Link to="/people" className="navbar-item" onClick={burgerClick}>
                People
              </Link>
            )} */}

            {userObj.isLoggedIn && userObj.isAdmin && (
              <Link to="/admin" className="navbar-item" onClick={burgerClick}>
                Admin
              </Link>
            )}
          </div>
          {/* RIGHT SIDE */}
          <div className="navbar-end">
            <div className="navbar-item">
              <p>{userObj.isLoggedIn ? userObj.name : ""} </p>
            </div>

            <div className="navbar-item">{userObj.hasManagees && <ManageesDropdown />}</div>
            <div className="navbar-item">
              <div className="buttons">
                {userObj.isLoggedIn && <SignOutButton />}
                {!userObj.isLoggedIn && <SignUpButton />}
                {!userObj.isLoggedIn && <SignInButton />}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
