import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DateSelector(props) {
  const parentDateChange = props.handleDateChange;
  const [assignedDate, setAssignedDate] = useState(props.assignedDate);

  function timeless(date) {
    const d = date;
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }
  function setTodayPlusNDays(n) {
    let todayPlusN = timeless(new Date());
    todayPlusN.setDate(todayPlusN.getDate() + n);
    handleChange(todayPlusN);
  }
  function handleChange(d) {
    const timelessDate = timeless(d);
    setAssignedDate(timelessDate);
    parentDateChange(timelessDate);
  }
  return (
    <div>
      <DatePicker
        selected={assignedDate}
        onChange={handleChange}
        dateFormat="yyyy/MM/dd"
        closeOnScroll={true}
        todayButton="Today"
      />
      <a
        onClick={() => {
          setTodayPlusNDays(0);
        }}
      >
        Today
      </a>{" "}
      <a
        onClick={() => {
          setTodayPlusNDays(1);
        }}
      >
        Tomorrow
      </a>
    </div>
  );
}
