import { useRecoilValue } from "recoil";
import { loggedInUserObjAtom } from "../recoil/atom.js";

import AssignCard from "../components/1-atoms/AssignCard";
import DateSelector from "../components/1-atoms/DateSelector.js";
import Furigana from "../components/1-atoms/Furigana";
import ManageesDropdown from "../components/1-atoms/ManageesDropdown";
import SignInButton from "../components/1-atoms/SignInButton";
import SignOutButton from "../components/1-atoms/SignOutButton";
import SignUpButton from "../components/1-atoms/SignUpButton";
import DebugUserObj from "../components/3-organisms/DebugUserObj";
import Footer from "../components/3-organisms/Footer";
import FooterDebug from "../components/3-organisms/FooterDebug";
import Nav from "../components/3-organisms/Nav";
import ViewEditCard from "../components/3-organisms/ViewEditCard.js";
import ViewOnlyCard from "../components/3-organisms/ViewOnlyCard";
// import Card from "./Card";
import CardNewAtRoot from "../components/5-pages/Card.js";
import Cards from "../components/5-pages/Cards";
import CardsGrid from "../components/5-pages/CardsGrid";
import Home from "../components/5-pages/Home";
import Login from "../components/5-pages/Login";
import People from "../components/5-pages/People";
import Register from "../components/5-pages/Register";
import TestPage from "../components/5-pages/TestPage";
import Today from "../components/5-pages/Today";
import Admin from "../components/5-pages/admin/Admin";
import AdminUsers from "../components/5-pages/admin/AdminUsers";
import Upload from "../components/5-pages/admin/Upload";

export default function AllComponents() {
  const userObj = useRecoilValue(loggedInUserObjAtom);
  function datePickerWhenChanged() {
    console.log("Dateselector changed");
  }
  return (
    <>
      <hr></hr>
      <p></p>
      <h1>AssignCard</h1> {/* <AssignCard /> */}
      <hr></hr>
      <p></p>
      <h1>DateSelector</h1>
      <DateSelector
        whenChanged={() => {
          console.log("Datepicker changed");
        }}
      />
      <hr></hr>
      <p></p>
      <h1>Furigana</h1> {/* <Furigana /> */}
      <hr></hr>
      <p></p>
      <h1>ManageesDropdown</h1>
      {/* <ManageesDropdown /> */}
      <hr></hr>
      <p></p>
      <h1>SignInButton</h1>
      <SignInButton />
      <hr></hr>
      <p></p>
      <h1>SignOutButton</h1>
      <SignOutButton />
      <hr></hr>
      <p></p>
      <h1>SignUpButton</h1>
      <SignUpButton />
      <hr></hr>
      <p></p>
      <h1>DebugUserObj</h1>
      <DebugUserObj />
      <hr></hr>
      <p></p>
      <h1>Footer</h1>
      <Footer />
      <hr></hr>
      <p></p>
      <h1>FooterDebug</h1> {/* <FooterDebug /> */}
      <hr></hr>
      <p></p>
      <h1>Nav</h1> {/* <Nav /> */}
      <hr></hr>
      <p></p>
      <h1>SearchResults</h1> {/* <SearchResults /> */}
      <hr></hr>
      <p></p>
      <h1>ViewEditCard</h1> {/* <ViewEditCard /> */}
      <hr></hr>
      <p></p>
      <h1>ViewOnlyCard</h1> {/* <ViewOnlyCard /> */}
      <hr></hr>
      <p></p>
      <h1>Card</h1>
      {/* <Card /> */}
      <hr></hr>
      <p></p>
      <h1>CardNewAtRoot</h1> <CardNewAtRoot cardID="PNXW0QfajWL9WEPo6q5z" cardOwnerUID="oflXCQcK04wKMMwkU4nQ" />
      <hr></hr>
      <p></p>
      <h1>Cards</h1> {/* <Cards /> */}
      <hr></hr>
      <p></p>
      <h1>CardsGrid</h1> {/* <CardsGrid /> */}
      <hr></hr>
      <p></p>
      <h1>ForgottenPassword</h1> {/* <ForgottenPassword /> */}
      <hr></hr>
      <p></p>
      <h1>Home</h1> {/* <Home /> */}
      <hr></hr>
      <p></p>
      <h1>Login</h1> {/* <Login /> */}
      <hr></hr>
      <p></p>
      <h1>People</h1> {/* <People /> */}
      <hr></hr>
      <p></p>
      <h1>Register</h1> {/* <Register /> */}
      <hr></hr>
      <p></p>
      <h1>TestPage</h1> {/* <TestPage /> */}
      <hr></hr>
      <p></p>
      <h1>Today</h1> {/* <Today /> */}
      <hr></hr>
      <p></p>
      <h1>Admin</h1> {/* <Admin /> */}
      <hr></hr>
      <p></p>
      <h1>AdminUsers</h1> {/* <AdminUsers /> */}
      <hr></hr>
      <p></p>
      <h1>Upload</h1> {/* <Upload /> */}
    </>
  );
}
