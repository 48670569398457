import React, { useState, useEffect } from "react";

import ManageesDropdown from "../1-atoms/ManageesDropdown";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { loggedInUserObjAtom, manageesObjAtom, viewingAsObjAtom } from "../../recoil/atom";
import CardsGrid from "./CardsGrid";

export default function People() {
  const navigate = useNavigate();
  const userObj = useRecoilValue(loggedInUserObjAtom);
  const [viewingAsUserObj, setViewingAsUserObj] = useRecoilState(viewingAsObjAtom);
  console.log("In People, userObj:", userObj);
  console.log("In People,  viewingAsUserObj:", viewingAsUserObj);

  useEffect(() => {
    if (!userObj.isLoggedIn) {
      navigate("/login");
    }
  }, [userObj.isLoggedIn]);

  // function handleSelection(userObj) {
  //   setViewingAsUserObj(userObj);
  // }
  return (
    <div>
      {/* <ManageesDropdown managees={userObj.managees} setSelectedManageeFunc={handleSelection} /> */}
      <ManageesDropdown />
      <h1>
        <br></br>
      </h1>
    </div>
  );
}

/////////////////////////////////////////////////////////////////////////////
////////////// from chatgpt /////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

// const MyComponent = () => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     const db = firebase.firestore();
//     // Create a reference to the document to include in the query
//     const ref = db.collection('users').doc('alice');

//     // Execute the query to retrieve the posts
//     const postsQuery = db.collection("posts").where("author", "==", ref).get();
//     // Execute the query to retrieve the user
//     const userQuery = ref.get();

//     // Use Promise.all to wait for both queries to finish
//     Promise.all([postsQuery, userQuery])
//       .then(([postsSnapshot, userSnapshot]) => {
//         // Extract the data from the query snapshots
//         const posts = postsSnapshot.docs.map((doc) => {
//           return {
//             id: doc.id,
//             ...doc.data()
//           };
//         });
//         const user = userSnapshot.data();

//         // Add the user data to each post
//         const postsWithUser = posts.map((post) => {
//           return { ...post, user };
//         });
//         setData(postsWithUser);
//       });
//   }, []);

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
