import React, { useState, useEffect } from "react";
import { db } from "../../util/firebaseconfig";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { loggedInUserObjAtom, viewingAsObjAtom, cardsViewTypeAtom } from "../../recoil/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { getCards } from "../../util/firebase_utils";

export default function CardsGrid() {
  const navigate = useNavigate();
  const userObj = useRecoilValue(loggedInUserObjAtom);
  const [viewingAsUserObj, setViewingAsUserObj] = useRecoilState(viewingAsObjAtom);

  const [cardData, setCardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!userObj.isLoggedIn) {
      navigate("/login");
    }
    const onCardsLoaded = () => {
      setIsLoading(false);
    };
    const viewUser = viewingAsUserObj.dbUID ? viewingAsUserObj.dbUID : userObj.dbUID;
    getCards(db, viewUser, onCardsLoaded).then((data) => {
      setCardData(data);
    });
    //  setCardStore(data);
  }, [userObj.isLoggedIn, viewingAsUserObj]);

  // useEffect(() => {
  //   async function fetchData() {
  //     const q = query(collection(db, "users", userObj.dbUID, "cards"), orderBy("title", "desc"));

  //     try {
  //       const docsPromise = await getDocs(q);
  //       const data = docsPromise.docs.map((doc) => ({
  //         id: doc.id,
  //         title: doc.get("title"),
  //         body: doc.get("body"),
  //         keypoint: doc.get("keypoint"),
  //         studyDate: doc.get("studyDate"),
  //         bodyTrunc: doc.get("body").substring(0, 30),
  //       }));
  //       setCardData(data);
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.log("Error while fetching data");
  //       console.log(error);
  //     }
  //   }
  //   if (userObj.isLoggedIn) {
  //     fetchData();
  //   } else {
  //     navigate("/login");
  //   }
  // }, [userObj.isLoggedIn, props.viewUID]);

  return (
    <div className="columns is-centered">
      <div className="editgrid">
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Key Point</th>
              <th>Content</th>
              <th>Study Date</th>
            </tr>
          </thead>
          <tbody>
            {cardData.map((card) => (
              <React.Fragment key={card.id}>
                <tr
                  onClick={() => {
                    navigate("/card/" + card.id);
                  }}
                >
                  <td>{card.data.title}</td>
                  <td>{card.data.keypoint}</td>
                  <td>{card.data.body}</td>
                  <td>{card.data.studyDate ? card.data.studyDate.toString() : "-"}</td>
                  {/* <td>{card.title}</td>
                  <td>{card.keypoint}</td>
                  <td>{card.body}</td>
                  <td>{card.studyDate}</td> */}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
