import { useState, useEffect } from "react";
import { collection, getDocs, getDoc, query, orderBy, where, doc, collectionGroup } from "firebase/firestore";
import { useRecoilValue, useRecoilState } from "recoil";
import { manageesObjAtom } from "../recoil/atom";

export function useManagees(db, userObj) {
  const [manageess, setManagees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  //   const [manageesObj, setManageesObj] = useRecoilState(manageesObjAtom);
  console.log("<<<<<<< ENTERING HOOK");
  console.log(userObj);

  useEffect(() => {
    async function fetchData() {
      console.log("<< Entering fetchdata");
      setIsLoading(true);
      setError(null);
      setManagees([]);

      try {
        console.log("<< in try");
        const managerDoc = doc(db, "users", userObj.dbUID);
        const q = query(collection(db, "permissions"), where("manager", "==", managerDoc));
        const querySnapshot = await getDocs(q);
        const manageesData = [];
        const promises = [];
        querySnapshot.forEach((permission) => {
          console.log("permission:", permission);
          const manageeUserRef = permission.data().managee;
          promises.push(
            getDoc(manageeUserRef).then((manageeUserDocSnap) => {
              const thisManagee = manageeUserDocSnap.data();
              thisManagee.id = manageeUserDocSnap.id;
              console.log("thisManagee:", thisManagee);
              manageesData.push(thisManagee);
            })
          );
        });
        await Promise.all(promises);
        console.log("manageesData--->", manageesData);
        // setManagees(manageesData);
        setManagees(...manageess, [3, 6, 8]);
        console.log("+++++++++managees", manageess);
        setIsLoading(false);
      } catch (error) {
        console.log("Error while fetching data");
        console.log(error);
        setError(error);
        setIsLoading(false);
      }
    }

    if (userObj.isLoggedIn) {
      console.log("<< Enter");
      fetchData();
    }
  }, [db, userObj]);

  //   useEffect(() => {
  //     async function fetchData() {
  //       console.log("<< Entering fetchdata");
  //       setIsLoading(true);
  //       setError(null);
  //       setManagees([]);

  //       try {
  //         console.log("<< in try");
  //         const managerDoc = doc(db, "users", userObj.dbUID);
  //         const q = query(collection(db, "permissions"), where("manager", "==", managerDoc));
  //         const querySnapshot = await getDocs(q);
  //         const manageesData = [];

  //         querySnapshot.forEach(async (permission) => {
  //           console.log("permission:", permission);
  //           const manageeUserRef = permission.data().managee;
  //           const manageeUserDocSnap = await getDoc(manageeUserRef);
  //           const thisManagee = manageeUserDocSnap.data();
  //           thisManagee.id = manageeUserDocSnap.id;
  //           console.log("thisManagee:", thisManagee);
  //           manageesData.push(thisManagee);
  //           console.log("manageesData1", manageesData);
  //         });
  //         console.log("manageesData2", manageesData);
  //         setManagees(manageesData);
  //         console.log("managees", managees);
  //         setIsLoading(false);
  //       } catch (error) {
  //         console.log("Error while fetching data");
  //         console.log(error);
  //         setError(error);
  //         setIsLoading(false);
  //       }
  //     }

  //     if (userObj.isLoggedIn) {
  //       console.log("<< Enter");
  //       fetchData();
  //     }
  //   }, [db, userObj]);

  console.log("EXITING HOOK >>>>");
  const xx = { a: 3, b: 9 };
  //   return { manageess, isLoading, error };
  return { xx, isLoading, error };
}
