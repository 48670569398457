import React, { useState, useEffect } from "react";
import { db, auth } from "../../util/firebaseconfig";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useRecoilState } from "recoil";
import { loggedInUserObjAtom } from "../../recoil/atom";
// import Button from "@mui/material/ListItem";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { getManagees } from "../../util/firebase_utils";
// import DebugUserObj from "../3-organisms/DebugUserObj";
export default function Login() {
  const navigate = useNavigate();

  // Local states
  const [formEmail, setFormEmail] = useState(process.env.REACT_APP_LOGIN_DEFAULT);
  const [formEmailError, setFormEmailError] = useState("");
  const [formPassword, setFormPassword] = useState(process.env.REACT_APP_PASSWORD_DEFAULT);
  const [formPasswordError, setFormPasswordError] = useState("");
  const [formError, setFormError] = useState("");
  const [buttonStateSpinning, setButtonStateSpinning] = useState(false);

  // Recoil states
  const [userObj, setUserObj] = useRecoilState(loggedInUserObjAtom);

  const updateEmail = (e) => {
    setFormEmail(e.target.value);
    setFormError("");
  };
  const updatePassword = (e) => {
    setFormPassword(e.target.value);
    setFormError("");
  };
  const handleSubmit = () => {
    // validateForm();
    setFormError("");
    submitForLogin();
  };

  const submitForLogin = async () => {
    let userData = {};
    try {
      ////////////////////////////
      // AUTH SIGN IN
      const userCredential = await signInWithEmailAndPassword(auth, formEmail, formPassword);
      userData.authEmail = userCredential.user.email;
      userData.authUID = userCredential.user.uid;

      ////////////////////////////
      // DB USER RECORD COLLECTION
      const q1 = query(collection(db, "users"), where("authUID", "==", userCredential.user.uid));
      const dbUsersQuerySnapshot = await getDocs(q1);
      const dbUserRecord = dbUsersQuerySnapshot.docs[0];
      userData = {
        ...userData,
        dbUID: dbUserRecord.id,
        ...dbUserRecord.data(),
      };
      console.log("Should be about to add dbUID", userData);

      ////////////////////////////
      // MANAGEES' REFERENCES
      const q2 = query(collection(db, "users", userData.dbUID, "access"), where("type", "==", "student"));
      const managees = await getDocs(q2);

      ////////////////////////////
      // MANAGEES' DATA
      const manageesDataPromises = [];
      managees.forEach((managee) => {
        manageesDataPromises.push(getDoc(doc(db, "users", managee.data().userRef.id)));
      });

      const manageesData = await Promise.all(manageesDataPromises);

      userData.managees = [];
      manageesData.forEach((managee) => {
        const manageeData = {
          dbUID: managee.id,
          ...managee.data(),
        };

        userData.managees.push(manageeData);
      });

      userData.hasManagees = userData.managees.length > 0 ? true : false;

      userData.isLoggedIn = true; // only set this once everything is resolved, otherwise the page will rerender
      console.log("userData.", userData);
      setUserObj(userData);
    } catch (error) {
      setButtonStateSpinning(false);
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(error.code);
      console.log(error.message);

      error.code === "auth/network-request-failed" && setFormError("No internet connection.");
      error.code === "auth/user-not-found" && setFormError("Please check the email you typed.");
      error.code === "auth/auth/wrong-password" && setFormError("Please check the password you typed.");
    }
  };

  const submitForLoginWORKING = () => {
    setButtonStateSpinning(true);

    signInWithEmailAndPassword(auth, formEmail, formPassword)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log("AUTH: Sign in success. User:" + userCredential.user.email + " " + userCredential.user.uid);
        // Build up the user data, start with the two fields you got from Auth, add DB fields later
        const userData = {
          authEmail: userCredential.user.email,
          authUID: userCredential.user.uid,
        };
        // This was setting isLoggedIn and updating the userObj but it caused a problem by
        // triggering the "useEffect if(userObj.isLoggedIn" while the DB promise was still outstanding
        // Get user details from database
        const q = query(collection(db, "users"), where("authUID", "==", userCredential.user.uid));
        getDocs(q)
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const newUserData = {
                ...userData,
                isLoggedIn: true, // only set this once both Auth and DB promises are resolved
                dbUID: doc.id,
                dbEmail: doc.data().email,
                invitationCode: doc.data().invitationCode,
                isAdmin: doc.data().isAdmin,
                isCreator: doc.data().isCreator,
                name: doc.data().name,
              };
              setUserObj(newUserData);
              console.log("newuserdata", newUserData);
              getManagees(db, doc.id).then((result) => {
                console.log("result", result);
              });
            });
          })
          .catch((error) => {
            console.log("Error in getting user info from DB");
            console.log(error);
          });

        setButtonStateSpinning(false);
      })
      .catch((error) => {
        setButtonStateSpinning(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error.code);
        console.log(error.message);

        error.code === "auth/network-request-failed" && setFormError("No internet connection.");
        error.code === "auth/user-not-found" && setFormError("Please check the email you typed.");
        error.code === "auth/auth/wrong-password" && setFormError("Please check the password you typed.");
      });
  };

  useEffect(() => {
    if (userObj.isLoggedIn) {
      navigate("/today");
    }
  }, [userObj.dbUID]);

  return (
    <div>
      <div>
        <p></p>
      </div>

      {/* <DebugUserObj object={userObj} /> */}
      <div className="columns   is-centered">
        <div className="column is-half">
          <section className="section "></section>
          <div className="box">
            <section className="section ">
              <h1 className="title">Log in</h1>
              <section className=" ">
                <br />
              </section>

              {/* EMAIL */}
              <div className="field">
                {/* <label className="label">Email</label> */}
                <div className="control ">
                  <input
                    className={`input ${formEmailError === "" ? "" : "is-danger"} `}
                    type="email"
                    value={formEmail}
                    placeholder="Email"
                    onChange={updateEmail}
                  />
                </div>
                <p className="help is-danger">{formEmailError}</p>
              </div>

              {/* PASSWORD */}
              <div className="field">
                {/* <label className="label">Password</label> */}
                <div className="control ">
                  <input
                    className={`input ${formPasswordError === "" ? "" : "is-danger"} `}
                    type="email"
                    placeholder="Password"
                    value={formPassword}
                    onChange={updatePassword}
                  />
                </div>
                <p className="help is-danger">{formPasswordError}</p>
              </div>
              <br />

              {/* FORM ERROR */}
              <section className="hero is-danger">{formError}</section>
              <br />
              {/* BUTTONS */}
              <div className="field is-grouped">
                <div className="control">
                  <button className="button is-link" onClick={handleSubmit}>
                    {buttonStateSpinning ? <i className="fa fa-spinner fa-spin" /> : "Log in"}
                  </button>
                </div>
                <div className="control">
                  <button className="button is-link is-light">Cancel</button>
                </div>
              </div>
              <br />

              {/* REGISTER / RESET */}
              <p>
                <a href="/forgottenpassword">Forgot your password</a>
              </p>
              <p>
                Don't have an account? <a href="/register">Register now</a>
              </p>
            </section>
          </div>{" "}
          <section className="section "></section>
        </div>
      </div>
    </div>
  );
}
