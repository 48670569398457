import React from "react";

export default function SignUpButton() {
  // Local
  // const [buttonStateSpinning, setButtonStateSpinning] = useState(false);
  // Recoil

  return (
    <>
      {/* <button className="button is-primary" href="/register">
        <strong>Sign up</strong>
      </button> */}
      <a className="button is-link" href="/register">
        <strong>Sign up</strong>
      </a>
    </>
  );
}
