import { atom } from "recoil";

const storeLocally =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const loggedInUserObjAtom = atom({
  key: "loggedInUserObj",
  default: 1,
  effects: [storeLocally("logged_in_user_obj")],
});

export const imitatingUserObjAtom = atom({
  key: "imitatingUserObj",
  default: {},
});

export const manageesObjAtom = atom({
  key: "manageesObjAtom",
  default: [],
  effects: [storeLocally("managees_obj")],
});

export const viewingAsObjAtom = atom({
  key: "viewingAsObjAtom",
  default: {},
  effects: [storeLocally("viewing_as")],
});

export const cardStoreAtom = atom({
  key: "cardStore",
  default: [],
  effects: [storeLocally("card_store")],
});

export const todayCard = atom({
  key: "todayCard",
  default: {},
  effects: [storeLocally("today_card")],
});
export const cardsViewTypeAtom = atom({
  key: "cardsViewTypeAtom",
  default: "history",
  effects: [storeLocally("cards_view_type")],
});
