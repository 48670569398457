import { signOut } from "firebase/auth";
// import { auth } from "./firebase";

export function addOne(numb) {
  return numb + 1;
}

export function fireBaseAuthSignOut(auth) {
  console.log("Signing out");
  console.log(auth);
  signOut(auth)
    .then(() => {
      return 0;
    })
    .catch((error) => {
      console.log("An error occurred trying to sign out");
      return -1;
    });
  return -2;
}

export function isValidDate(date) {
  return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

export function getTodayMidnight() {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  return today;
}

export function areDatesSame(d1, d2) {
  if (d1.getDate() == d2.getDate() && d1.getMonth() == d2.getMonth() && d1.getYear() == d2.getYear()) {
    return true;
  }
  return false;
}
export function dateFloor(d) {
  const dfloor = new Date(d);
  dfloor.setHours(0);
  dfloor.setMinutes(0);
  dfloor.setSeconds(0);
  dfloor.setMilliseconds(0);
  return dfloor;
}
export function daysDiff(d1, d2) {
  const d1b = dateFloor(new Date(d1));
  // d1b.setHours(0);
  // d1b.setMinutes(0);
  // d1b.setSeconds(0);
  // d1b.setMilliseconds(0);
  const d2b = dateFloor(new Date(d2));
  // const d2b = new Date(d2);
  // d2b.setHours(0);
  // d2b.setMinutes(0);
  // d2b.setSeconds(0);
  // d2b.setMilliseconds(0);
  const diffmilliseconds = d2 - d1;
  const diffdays = diffmilliseconds / (1000 * 60 * 60 * 24);
  return diffdays;
}
