import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { loggedInUserObjAtom, viewingAsObjAtom } from "../../recoil/atom";
import AssignCard from "../1-atoms/AssignCard";
import { getCard } from "../../util/firebase_utils";
import { db } from "../../util/firebaseconfig";
import ViewEditCardNew from "../3-organisms/ViewEditCard";

export default function Card() {
  const location = useLocation();
  const { cardddData } = location.state || {};

  const [currentCardData, setCurrentCardData] = useState(cardddData);
  const userObj = useRecoilValue(loggedInUserObjAtom);
  const viewingAsUserObj = useRecoilValue(viewingAsObjAtom);
  const [viewUser, setViewUser] = useState("");

  useEffect(() => {
    // If currentcarddata.owner != viewingas then navigate to cards
  }, [viewingAsUserObj]);

  return (
    <div>
      {/* <p>
        <strong>Props</strong>
      </p>
      <p>ID: {cardddData.id||}</p>
      <p>OwnerID: {cardddData.ownerUID}</p>
      <p>
        <strong>Retrieved Card Data</strong>
      </p>
      <p>id: {currentCardData.id}</p>
      <p>ownerUID: {currentCardData.ownerUID}</p>
      <p>title: {currentCardData.title}</p>
      <p>keypoint: {currentCardData.keypoint}</p>
      <p>body: {currentCardData.body}</p> */}

      <ViewEditCardNew cardData={currentCardData} />
      {/* <ViewEditCardNew cardData={{ title: "aa", body: "bbb", keypoint: "xxkeypoint" }} /> */}
      {/* <ViewCardx card={{ title: "ggg" }} /> */}
      {/* <ViewEditCard user={viewingAsUserObj.dbUID} cardID={cardIDx} cardData={cardData} cardOwner={viewUser} /> */}
      {/* {userObj.hasManagees && <AssignCard cardID={cardIDx} cardData={cardData} />} */}
    </div>
  );
}

// users/oflXCQcK04wKMMwkU4nQ/cards/PNXW0QfajWL9WEPo6q5z
///users/oflXCQcK04wKMMwkU4nQ/cards/PNXW0QfajWL9WEPo6q5z
